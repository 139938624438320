import React, {useState, useEffect} from "react";
import "./Accounting.css";
import ListGroup  from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import {getUserBalance, loadFlights} from "../libs/databaseAccess";
import "./Home.css";
import "./userAccount";
import { DateTime } from "luxon";
import { Button } from "react-bootstrap";


export default function Home() {
  const { userID, club, clubName, clubLink, clubLogo, clubMessage, isAuthenticated, userIsAdmin, userCanAddFunds, userCanAddIntros, userCanUpdateGliders, userIsActive, clubTimeZone } = useAppContext();
  const [activeFlights, setActiveFlights] = useState([]);
  const [balance, setBalance] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated || club === "" || userID === "") {
        return;
      }
  
      try {
        // Set up current date string
        const dateString = DateTime.fromObject({}, {zone: clubTimeZone}).toISODate()

        // // Load initial flight data
        let todaysFlights = await loadFlights(club, dateString);
        // // Filter for flights still in the air and sort by launch time
        const activeFlights = todaysFlights.filter(a => !a.landedAt && !a.cancelledAt).filter(a => a.user === userID);

        setActiveFlights(activeFlights);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated, club, userID, clubTimeZone]);


  useEffect(() => {
    if (!isAuthenticated || club === "" || userID === "") {
      return;
    }

    async function recentTrans(){
      const balance = await getUserBalance(club, userID);
      setBalance(balance.toFixed(2));
    }

  recentTrans();
  }, [club, isAuthenticated, userID, clubTimeZone]);


  function renderFlights(flights) {
    return (
      <>
      <hr></hr>
      <h4>Your active flights: </h4>
      <div className="flights">
          { (flights.length === 0) ? <p>None</p> : 
          <>
            { flights.map((flight, i) => 
              <p key={flight.SK}>
                {flight.glider}
              </p>)
            }
          </>
          }
      </div>
      </>
    );
  }

  function renderBalance() {
    return(
    <>
    { (balance !== null) &&
    <h4 className="my-0">Your balance: ${balance}</h4>
    }
    </>
    );
  }

  function renderMessage() {
    return(
      <>
      <h4 className="my-0"><div dangerouslySetInnerHTML={{ __html: clubMessage }}/></h4>
      </>
    )
  }

  function renderLander() {
    return (
      <>
      <div className="lander text-center">
        <h1 className="fw-semibold">Take Up Slack</h1>
        <div className="text-body-secondary">
          <p>Take Up Slack is a glider flight line management tool designed for smartphones. Easily track launches, landings and associated costs. </p>
          <p>You and your members will get customized reporting for personal and glider logbook entries,
            for club level metrics, and for your treasurer. </p>
          <p>Would you be interested in a demo or trial at your club? <a href="mailto:patrick@paretoenergy.ca">Send us an email</a></p>
          <p>From the team that brought you the <a href="https://soaringtasks.com/">Proving Grounds</a></p>
        </div>
      </div>
      <div className="text-center">
        <ListGroup>
          <LinkContainer key="signup" to="/signup/">
            <Button variant="outline-dark" className="mb-2">Sign Up</Button>
          </LinkContainer>
          <LinkContainer key="login" to="/login">
            <Button variant="outline-dark">Log In</Button>
          </LinkContainer>
        </ListGroup>
      </div>
      </>
    );
  }

  function renderPageHeading(){
    return(
      <div className="header">
        <div className = "clubIconContainer"> 
          <a href= {clubLink}>
            <img className = "clubIcon" src = {clubLogo} alt="club logo"></img>
          </a>
        </div>
        <h2 className="w-100 mb-0" id = "clubName">{clubName}</h2>
      </div>
    )
  }

  function renderActivities() {
    if (club === "None") {
      navigate("/settings")
    } else {
      return (
        <>
          <div className="text-center d-flex flex-column gap-3 py-3">
            { clubName === "" ? 
              <h2>Choose your activity</h2> :
              renderPageHeading()  
            }
            { userIsActive ? 
              <>
                <section className="d-flex flex-column gap-2">
                  <h3 className = "text-start mb-0">Gliding</h3>
                  <LinkContainer key = "gogliding" to = "/crew">
                    <Button variant="outline-dark" size="lg">Request Flight</Button>
                  </LinkContainer>
                  <LinkContainer key = "ground" to = "/ground">
                    <Button variant="outline-dark" size="lg">Record Launch or Landing</Button>
                  </LinkContainer>
                </section>
                <section className="d-flex flex-column gap-2">
                  <h3 className = "text-start mb-0">Tow Pilots</h3>
                  <LinkContainer key = "fuel" to = "/fuel">
                    <Button variant="outline-dark" size="lg">Log Fuel, Oil, or Shift Change</Button>
                  </LinkContainer>
                  <LinkContainer key = "awaiting" to = "/awaiting">
                    <Button variant="outline-dark" size="lg">Awaiting Launches</Button>
                  </LinkContainer>
                </section>
                <section className="d-flex flex-column gap-2">
                  <h3 className = "text-start mb-0">Records</h3>
                  <LinkContainer key = "logs" to = "/logs">
                    <Button variant="outline-dark" size="lg">Flight Logs</Button>
                  </LinkContainer>
                  <LinkContainer key = "userAccount" to = "/userAccount">
                    <Button variant="outline-dark" size="lg">User Account Summary</Button>
                  </LinkContainer>
                  <LinkContainer key = "emergency" to = "/emergency">
                    <Button variant="outline-dark" size="lg">Emergency Contacts / Directory</Button>
                  </LinkContainer>
                  </section>
                { (userIsAdmin || userCanAddFunds || userCanAddIntros || userCanUpdateGliders) &&
                  <section className="d-flex flex-column gap-2">
                    <h3 className = "text-start mb-0">Back Office</h3>
                    { (userIsAdmin || userCanAddFunds || userCanAddIntros || userCanUpdateGliders) &&
                      <LinkContainer key = "admin" to = "/admin">
                        <Button variant="outline-dark" size="lg">Admin</Button>
                      </LinkContainer>
                    }
                    { userIsAdmin &&
                      <LinkContainer key = "accounts" to = "/accounts">
                        <Button variant="outline-dark" size="lg">Accounting Charts</Button>
                      </LinkContainer>
                    }
                    { userIsAdmin &&
                      <LinkContainer key = "accountsAdmin" to = "/accountingAdmin">
                        <Button variant="outline-dark" size="lg">Accounting Admin</Button>
                      </LinkContainer>
                    }
                  </section>
                }
              </> :
              <section className="user not activated">
                <h1>Your account has not been activated at this club</h1>
                <p>Contact a club admin to have your account activated</p>
                <hr></hr>
              </section>
            }
          </div>
          <hr/>
          {renderBalance()}
          { (activeFlights.length > 0 ) && 
            renderFlights(activeFlights)
          }
          <hr/>
          {(clubMessage !== "") &&
            renderMessage()
          }
        </>
      );
    }

  }

  function renderSelectClub () {
    return (
      <>
        <div className="p-4">
          <div className="user not activated">
            <h1>Your account does not have an active club</h1>
            <p>Please go to settings, and request memebrship at a club. Then select that club as your active club.</p>
          </div>
        </div>
        <hr className="my-0" />
      </>
    )
  }

  function renderLoader(){
    return(
      <>
        <div className="splash-screen">
          { isAuthenticated ? 
            <>Retrieving your data....
              <div className="loading-dot">.</div>
            </> 
          : renderLander()}
        </div>  
      </>
    )
  }

  return (
    <div className="Home">
      {!isAuthenticated || club === "" ? 
        renderLoader() :
        (club !== "None") ? 
          renderActivities() :
          renderSelectClub()
      }
    </div>
  );
}